import React from "react";

const HeaderPhone = () => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.023 15.488c-.63-.015-1.423-.052-1.924-.14a14.67 14.67 0 01-1.653-.425 1.017 1.017 0 00-1.019.253L13.21 17.38a15.376 15.376 0 01-3.798-2.792A15.376 15.376 0 016.62 10.79l2.204-2.218c.266-.267.365-.659.253-1.019-.142-.458-.329-1.11-.424-1.652-.089-.501-.125-1.294-.141-1.924A1 1 0 007.51 3H4c-.438 0-1 .328-1 1 0 4.539 1.84 8.874 4.966 12.034C11.126 19.16 15.461 21 20 21c.672 0 1-.562 1-1v-3.51a1 1 0 00-.977-1.002z"
        fill="#000"
      />
    </svg>
  );
};

export { HeaderPhone };
