import React, { useState, useEffect } from "react";
import cn from "classnames";
import { Button } from "../Button";
import { Spinner } from "../Spinner";
import { useForm } from "./useForm";
import { Input } from "./Input";
import { PhoneInput } from "./PhoneInput";
import { TextErrors } from "./textErrors";

import "./styles.css";

const Form = ({
  className,
  theme = "dark",
  title,
  text,
  namePlaceholder,
  phoneNumberPlaceholder,
  buttonLabel,
  policy,
  successMessage,
  successButtonLabel,
  errorMessage,
  errorButtonLabel,
}) => {
  const [ErrorNameEmpty, setErrorEmpty] = useState(false);
  const [ErrorPhoneNumberEmpty, setErrorPhoneNumberEmpty] = useState(false);
  const [ErrorPhoneNumberWrongFormat, setErrorPhoneNumberWrongFormat] = useState(false);
  const {
    nameProps,
    phoneNumberProps,
    resetForm,
    onSubmit,
    FormStatus: { isLoading, done, error },
  } = useForm(setErrorEmpty, setErrorPhoneNumberEmpty, setErrorPhoneNumberWrongFormat);

  const [lang, setLang] = useState("ru");
  useEffect(() => {
    if (window.location.href.split("-")[2] === "en" || window.location.href.split("/")[3] === "en") {
      setLang("en");
    } else {
      setLang("ru");
    }
  }, []);

  const textErrors = [
    { error: ErrorNameEmpty, textError: lang === "ru" ? "Введите ваше имя" : "Enter your name" },
    {
      error: ErrorPhoneNumberEmpty || ErrorPhoneNumberWrongFormat,
      textError: lang === "ru" ? "Проверьте, пожалуйста, номер телефона" : "Please check your phone number",
    },
  ];
  return (
    <div className={cn(className, "Form", `Form_theme_${theme}`)}>
      <div className="Font_smallHeader">{title}</div>
      <div className="[ Form__text ] [ Font_regularText ]">{text}</div>
      {!done && (
        <>
          <form className="Form__form" onSubmit={onSubmit}>
            <Input type="text" placeholder={namePlaceholder} property={nameProps} errorEmpty={ErrorNameEmpty} />
            <PhoneInput
              type="text"
              placeholder={phoneNumberPlaceholder}
              property={phoneNumberProps}
              errorEmpty={ErrorPhoneNumberEmpty}
              errorFormat={ErrorPhoneNumberWrongFormat}
            />

            <Button className="Form__button" color={theme === "light" && "black"}>
              {!isLoading ? buttonLabel : <Spinner />}
            </Button>
          </form>

          <TextErrors className="Form__textErrors" errors={textErrors} />
          <div className="[ Form__policy ] [ Font_smallText Color_darkGrey ]">{policy}</div>
        </>
      )}
      {done && !error && (
        <div className="Form__feedback">
          <p className="[ Font_mediumTextBold ]">{successMessage}</p>
          <Button color="grey" onClick={resetForm}>
            {successButtonLabel}
          </Button>
        </div>
      )}
      {done && error && (
        <div className="Form__feedback">
          <p className="[ Font_mediumTextBold ]">{errorMessage}</p>
          <Button onClick={resetForm} color={theme === "light" && "black"}>
            {errorButtonLabel}
          </Button>
        </div>
      )}
    </div>
  );
};

export { Form };
