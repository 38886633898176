import cn from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useKeyHandler } from "../../hooks/useKeyHandler";
import "./styles.css";

const DropDownMenu = ({ className, isOpen, onClose, children }) => {
  const [activeItemIndex, setActiveItemIndex] = useState(-1);

  const menu = useRef(null);
  const menuItems = useRef([]);
  const numberOfMenuItems = menuItems.current.length;

  useEffect(() => {
    if (menu.current) {
      menuItems.current = Array.from(menu.current.querySelectorAll('[role^="menuitem"]:not([disabled])'));
    }
  }, []);

  const focusMenuItemHandler = useCallback(index => {
    const menuItem = menuItems.current[index];

    if (!menuItem) return;

    setActiveItemIndex(index);
    menuItem.focus();
  }, []);

  const handleFocusNext = useCallback(
    e => {
      e.preventDefault();
      const nextItemIndex = activeItemIndex < numberOfMenuItems - 1 ? activeItemIndex + 1 : 0;
      focusMenuItemHandler(nextItemIndex);
    },
    [activeItemIndex, numberOfMenuItems, focusMenuItemHandler]
  );

  const handleFocusPrevious = useCallback(
    e => {
      e.preventDefault();
      const previousItemIndex = activeItemIndex > 0 ? activeItemIndex - 1 : numberOfMenuItems - 1;
      focusMenuItemHandler(previousItemIndex);
    },
    [activeItemIndex, numberOfMenuItems, focusMenuItemHandler]
  );

  useEffect(() => {
    if (isOpen) {
      focusMenuItemHandler(0);
    }
  }, [isOpen, focusMenuItemHandler]);

  const handleKeyDown = useKeyHandler({
    40: handleFocusNext, // Arrow down
    38: handleFocusPrevious, // Arrow Up
    9: onClose, // Tab
    27: onClose, // Escape
  });

  const handleMouseOver = useCallback(
    e => {
      const menuItemIndex = menuItems.current.indexOf(e.target);
      focusMenuItemHandler(menuItemIndex);
    },
    [focusMenuItemHandler]
  );

  return (
    <ul
      className={cn(className, "DropDownMenu", { DropDownMenu_close: !isOpen })}
      ref={menu}
      role="menu"
      onKeyDown={handleKeyDown}
      onFocus={handleMouseOver}
      onMouseOver={handleMouseOver}
    >
      {children}
    </ul>
  );
};

export { DropDownMenu };
