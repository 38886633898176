import { useState, useCallback, useMemo } from "react";

const useForm = (setErrorEmpty, setErrorPhoneNumberEmpty, setErrorPhoneNumberWrongFormat) => {
  /*NAME*/
  const [name, setName] = useState("");
  const handleChangeName = useCallback(
    e => {
      setErrorEmpty(false);
      setName(e.target.value);
    },

    [setErrorEmpty]
  );

  /*PHONENUMBER*/
  const [phoneNumber, setPhoneNumber] = useState("");
  const handleChangePhoneNumber = useCallback(
    e => {
      setErrorPhoneNumberEmpty(false);
      setErrorPhoneNumberWrongFormat(false);
      //add plus
      if (e.target.value.substring(0, 1) === "+") {
        setPhoneNumber(e.target.value);
      } else {
        setPhoneNumber("+" + e.target.value);
      }
      //remove plus  if empty field
      if (e.target.value === "+") {
        setPhoneNumber("");
      }
    },
    [setErrorPhoneNumberEmpty, setErrorPhoneNumberWrongFormat]
  );

  const [{ isLoading, done, error }, setQueryState] = useState({ isLoading: false, done: false, error: false });

  const resetForm = useCallback(() => {
    setName("");
    setPhoneNumber("");
    setQueryState({ isLoading: false, done: false, error: false });
  }, []);

  /* FORM SUBMIT */
  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();

      setQueryState({ isLoading: true, done: false, error: false });

      const response = await fetch("/api/form", {
        method: "POST",
        body: JSON.stringify({ Имя: name, "Номер телефона": phoneNumber }),
        headers: {
          "content-type": "application/json",
        },
      });

      if (!response.ok) {
        return setQueryState({ isLoading: false, done: true, error: true });
      }

      setQueryState({ isLoading: false, done: true, error: false });
    },
    [name, phoneNumber]
  );

  /* VALIDATE BEFORE SUBMIT */
  const validateBeforeSubmitHandler = useCallback(
    e => {
      let error = false;
      e.preventDefault();

      /*empty */
      if (name === "" || undefined) {
        setErrorEmpty(true);
        error = true;
      }
      if (phoneNumber === "" || undefined) {
        setErrorPhoneNumberEmpty(true);
        error = true;
      }
      /*format min number*/
      /*6 - 1(+) = 5 number symbol*/

      if (phoneNumber.length < 6) {
        setErrorPhoneNumberWrongFormat(true);
        error = true;
      }

      if (!error) {
        handleSubmit(e);
      }
    },
    [handleSubmit, name, phoneNumber, setErrorEmpty, setErrorPhoneNumberEmpty, setErrorPhoneNumberWrongFormat]
  );

  /* memo data */

  const result = useMemo(
    () => ({
      nameProps: { value: name, onChange: handleChangeName },
      phoneNumberProps: { value: phoneNumber, onChange: handleChangePhoneNumber },
      FormStatus: { isLoading, done, error },
      resetForm,
      onSubmit: validateBeforeSubmitHandler,
    }),
    [
      name,
      handleChangeName,
      phoneNumber,
      handleChangePhoneNumber,
      isLoading,
      done,
      error,
      resetForm,
      validateBeforeSubmitHandler,
    ]
  );
  return result;
};

export { useForm };
