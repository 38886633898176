import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import { Email } from "../../icons/Email";
import { Marker } from "../../icons/Marker";
import { Phone } from "../../icons/Phone";
import { Form } from "../Form";
import { Skeleton } from "../Skeleton";
import "./styles.css";
import { YellowSquareLogo } from "./YellowSquareLogo";

const Footer = ({ form, contactsLabel, address, email, phoneNumbers, logoWhite, lang }) => {
  const [mapSrc, setMapSrc] = useState("");
  useEffect(() => {
    window.onload = function() {
      setMapSrc(
        "https://yandex.com/map-widget/v1/?um=constructor%3Ab36c7d6c6c3df2f2f626d7259fcf506b6f698b35c39af2619098f0e0ef5eb5f8&amp;source=constructor&lang=ru_RU"
      );
    };

    setTimeout(
      () =>
        setMapSrc(
          "https://yandex.com/map-widget/v1/?um=constructor%3Ab36c7d6c6c3df2f2f626d7259fcf506b6f698b35c39af2619098f0e0ef5eb5f8&amp;source=constructor&lang=ru_RU"
        ),
      3000
    );
  }, []);
  const logo = <YellowSquareLogo />;

  return (
    <div id="footer" className="[ Footer ] [ Color_white ]">
      <div className="Content sm:Content_medium">
        <div className="Footer__wrapper">
          <Form className="Footer__form" {...form} />

          <div className="Footer__contacts">
            <div className="Footer__map">
              {mapSrc ? <iframe width="100%" height="100%" title="Карта" src={mapSrc} frameBorder="0" /> : <Skeleton />}
            </div>

            <div className="[ Footer__info ] [ Font_regularText ]">
              <div className="[ Footer__contactsLabel ] [ Font_mediumTextBold ]">{contactsLabel}</div>
              <div className="Footer__contactsList">
                <div className="Footer__contact">
                  <Marker />
                  <span>{address}</span>
                </div>

                {phoneNumbers.map(phoneNumber => (
                  <div key={phoneNumber} className="Footer__contact">
                    <Phone />
                    <a className="[ Color_white ]" href={`tel:${phoneNumber}`}>
                      {phoneNumber}
                    </a>
                  </div>
                ))}

                <div className="Footer__contact">
                  <Email />
                  <span>
                    <a className="[ Color_white ]" href={`mailto:${email}`}>
                      {email}
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="Footer__bottom">
          <div className="Footer__bottomLeft">
            <Link to="/">
              <img className="Footer__logo" src={logoWhite.publicURL} alt="Логотип" />
            </Link>
            <div className="Footer__payerAccountNumber [ Font_smallText  Color_darkGrey ] ">
              ООО "ПрофПол" • УНП 192394620
            </div>
          </div>

          <a className="Footer__copyright" href="https://www.yellowsquare.dev/" target="_blank">
            <div>{lang === "en" ? `Created by` : `Разработано `}</div> <YellowSquareLogo />
          </a>
        </div>
      </div>
    </div>
  );
};

export { Footer };
