import React, { useEffect, useState } from "react";
import Img from "gatsby-image";

const ImagePreview = ({ src, ...props }) => {
  const [imageAspectRatio, setImageAspectRatio] = useState(0);

  useEffect(() => {
    const image = new Image();
    image.onload = function() {
      setImageAspectRatio(this.width / this.height);
    };
    image.src = src;
  }, [src]);

  return <Img fluid={{ src, aspectRatio: imageAspectRatio }} {...props} />;
};

export { ImagePreview };
