import React from "react";
import "./styles.css";
import cn from "classnames";

const Button = ({ className, color = "yellow", children, ...props }) => {
  return (
    <button className={cn("Button", `Button_color_${color}`, className)} {...props}>
      {children}
    </button>
  );
};

export { Button };
