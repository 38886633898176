import Img from "gatsby-image";
import React from "react";
import { ImagePreview } from "./ImagePreview";

const Image = ({ image, ...props }) => {
  if (typeof image === "string") {
    return <ImagePreview src={image} {...props} />;
  }

  return <Img {...image.childImageSharp} {...props} />;
};

export { Image };
