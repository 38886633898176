import React from "react";

const Email = props => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12 5c-3.858 0-7 3.142-7 7s3.142 7 7 7h3.5v-1.4H12A5.589 5.589 0 016.4 12c0-3.101 2.499-5.6 5.6-5.6 3.101 0 5.6 2.499 5.6 5.6v1.05a1.04 1.04 0 01-1.05 1.05 1.04 1.04 0 01-1.05-1.05V12A3.51 3.51 0 0012 8.5 3.51 3.51 0 008.5 12a3.51 3.51 0 003.5 3.5c.99 0 1.882-.42 2.52-1.087a2.454 2.454 0 002.03 1.087A2.46 2.46 0 0019 13.05V12c0-3.858-3.142-7-7-7zm0 4.9c1.168 0 2.1.932 2.1 2.1a2.09 2.09 0 01-2.1 2.1A2.09 2.09 0 019.9 12c0-1.168.932-2.1 2.1-2.1z"
        fill="#7E7E7E"
      />
    </svg>
  );
};

export { Email };
