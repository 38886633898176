import React from "react";

const Menu = props => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3 5a1 1 0 000 2h18a1 1 0 100-2H3zm0 6a1 1 0 100 2h18a1 1 0 100-2H3zm0 6a1 1 0 100 2h14a1 1 0 100-2H3z"
        fill="#000000"
      />
    </svg>
  );
};

export { Menu };
