import { Link } from "gatsby";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Headroom from "react-headroom";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { useSwitchLang } from "../../hooks/useSwitchLang";
import { HeaderPhone } from "../../icons/HeaderPhone";
import { Menu } from "../../icons/Menu";
import { DropDownMenu, DropDownMenuDivider, DropDownMenuLink } from "../DropDownMenu";
import { SolutionsMenu } from "./SolutionsMenu/SolutionsMenu";
import "./styles.css";

const Header = ({ address, email, phoneNumbers, logo, onOpenContactUsModal, lang }) => {
  const linkAfterSwitchLang = useSwitchLang(lang);

  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);
  const handleToggleMobileMenu = useCallback(() => setIsOpenMobileMenu(!isOpenMobileMenu), [isOpenMobileMenu]);
  const handleCloseMobileMenu = useCallback(() => setIsOpenMobileMenu(false), []);

  const mobileHeader = useRef(null);
  useOnClickOutside(mobileHeader, handleCloseMobileMenu);
  useEffect(() => {
    window.addEventListener("scroll", handleCloseMobileMenu, { passive: true });
  });

  const mainLink = "/" + (lang === "en" ? "en" : "");
  const shopEquipmentLink = "/shop-equipment" + (lang === "en" ? "-en" : "");
  const cashAreaEquipmentLink = "/cash-area-equipment" + (lang === "en" ? "-en" : "");
  const ceramicTileHolderLink = "/ceramic-tile-holder" + (lang === "en" ? "-en" : "");
  const metalConstructionsLink = "/metal-constructions" + (lang === "en" ? "-en" : "");
  const selfCheckoutLink = "/self-checkout" + (lang === "en" ? "-en" : "");

  return (
    <div className="Header">
      <div className="Header__contactsHeader">
        <div className="[ Header__contactsHeaderContainer ] [ Content md:Content_header sm:Content_medium Font_smallTextBold Color_white ]">
          <div className="Header__address">{address}</div>
          <div className="Header__contacts">
            <span>
              <a className="[ Color_white ]" href={`mailto:${email}`}>
                {email}
              </a>
            </span>
            {phoneNumbers.map(phoneNumber => (
              <a key={phoneNumber} href={`tel:${phoneNumber}`} className="[ Header__link ] [ Color_white ]">
                {phoneNumber}
              </a>
            ))}
          </div>
        </div>
      </div>
      <Headroom pinStart={32}>
        <div className="Header__main Header__main_desktop">
          <div className="[ Header__mainContainer ] [ Content md:Content_header sm:Content_medium ]">
            <Link to={mainLink}>
              <img className="Header__logo" src={logo.publicURL} alt="Логотип" />
            </Link>
            <nav>
              <ul className="Header__navigation">
                <li>
                  <Link className="[ Header__link ] [ Font_regularText Color_darkGrey ]" to={mainLink}>
                    {lang === "en" ? "Home" : "Главная"}
                  </Link>
                </li>
                <SolutionsMenu lang={lang} />
                <li>
                  <button
                    className="[ Header__link ] [ Font_regularText Color_darkGrey ]"
                    onClick={onOpenContactUsModal}
                  >
                    {lang === "en" ? "Contact us" : "Связаться с нами"}
                  </button>
                </li>
                <li>
                  <a className="[ Header__link ] [ Font_regularText Color_black ]" href={linkAfterSwitchLang}>
                    {lang === "en" ? "Русская версия" : "English version"}
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="Header__main Header__main_mobile">
          <div className="[ Header__mainContainer ] [ Content md:Content_header sm:Content_medium ]">
            <button className="Header__icon" onClick={handleToggleMobileMenu}>
              <Menu />
            </button>
            <Link to={mainLink}>
              <img className="Header__logo" src={logo.publicURL} alt="Логотип" />
            </Link>
            <a className="Header__icon" href={`tel:${phoneNumbers[0]}`}>
              <HeaderPhone />
            </a>

            <DropDownMenu className="Header__mobileMenu" isOpen={isOpenMobileMenu} onClose={handleCloseMobileMenu}>
              <DropDownMenuLink href={mainLink} onClick={handleCloseMobileMenu}>
                {lang === "en" ? "Home" : "Главная"}
              </DropDownMenuLink>
              <DropDownMenuLink href={shopEquipmentLink} onClick={handleCloseMobileMenu}>
                {lang === "en" ? "Shop equipment" : "Для магазинов"}
              </DropDownMenuLink>
              <DropDownMenuLink href={cashAreaEquipmentLink} onClick={handleCloseMobileMenu}>
                {lang === "en" ? "Checkout area equipment" : "Для кассовых зон"}
              </DropDownMenuLink>
              <DropDownMenuLink href={ceramicTileHolderLink} onClick={handleCloseMobileMenu}>
                {lang === "en" ? "Tile display stands" : "Стеллажи для плитки"}
              </DropDownMenuLink>
              <DropDownMenuLink href={metalConstructionsLink} onClick={handleCloseMobileMenu}>
                {lang === "en" ? "Metal frames" : "Металлокаркасы"}
              </DropDownMenuLink>
              <DropDownMenuLink href={selfCheckoutLink} onClick={handleCloseMobileMenu}>
                {lang === "en" ? "Self-checkout" : "Кассы саообслуживания"}
              </DropDownMenuLink>

              <DropDownMenuLink
                onClick={() => {
                  onOpenContactUsModal();
                  handleCloseMobileMenu();
                }}
              >
                {lang === "en" ? "Contact us" : "Связаться с нами"}
              </DropDownMenuLink>

              <DropDownMenuDivider />

              <div className="Header__mobileMenuContacts">
                <div className="Header__mobileMenuContacts_topLine">
                  <span className="Font_smallTextBold">{email}</span>
                  <a className="[ Font_smallTextBold Color_black ]" href={linkAfterSwitchLang}>
                    {lang === "en" ? "Русская версия" : "English version"}
                  </a>
                </div>

                {phoneNumbers.map(phoneNumber => (
                  <a key={phoneNumber} href={`tel:${phoneNumber}`} className="Font_smallTextBold Color_black">
                    {phoneNumber}
                  </a>
                ))}
              </div>
            </DropDownMenu>
          </div>
        </div>
      </Headroom>
    </div>
  );
};

export { Header };
