import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Modal } from "../Modal";
import { Form } from "../Form";
import { Image } from "../Image";

import "./styles.css";

const ContactUsModal = ({
  isOpen,
  title,
  text,
  namePlaceholder,
  phoneNumberPlaceholder,
  buttonLabel,
  policy,
  successMessage,
  successButtonLabel,
  errorMessage,
  errorButtonLabel,
  onClose,
}) => {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "components/ContactUsModal/modal-background.jpg" }) {
        childImageSharp {
          fixed(height: 348, quality: 90) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="ContactUsModal">
        <Form
          className="ContactUsModal__form"
          theme="light"
          title={title}
          text={text}
          namePlaceholder={namePlaceholder}
          phoneNumberPlaceholder={phoneNumberPlaceholder}
          buttonLabel={buttonLabel}
          policy={policy}
          successMessage={successMessage}
          successButtonLabel={successButtonLabel}
          errorMessage={errorMessage}
          errorButtonLabel={errorButtonLabel}
        />
        <Image className="ContactUsModal__background" image={data.background} />
      </div>
    </Modal>
  );
};

ContactUsModal.getLivePreviewData = ({ data, getAsset }) => ({
  contactUsModal: {
    ...data.contactUsModal,
  },
});

const contactUsModalQuery = graphql`
  fragment ContactUsModalFragment on MarkdownRemarkFrontmatter {
    contactUsModal {
      title
      text
      namePlaceholder
      phoneNumberPlaceholder
      buttonLabel
      policy
      successMessage
      successButtonLabel
      errorMessage
      errorButtonLabel
    }
  }
`;

export { ContactUsModal, contactUsModalQuery };
