import React from "react";

const Marker = props => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.6 4A5.6 5.6 0 006 9.6c0 4 5.6 10.4 5.6 10.4s5.6-6.4 5.6-10.4A5.6 5.6 0 0011.6 4zm0 7.6a2 2 0 110-4 2 2 0 010 4z"
        fill="#7E7E7E"
      />
    </svg>
  );
};

export { Marker };
