import cn from "classnames";
import React, { useRef } from "react";
import { useSlider } from "../../hooks/useSlider";
import { Image } from "../Image";
import "./styles.css";

const CompletedProjects = ({ title, examples }) => {
  const examplesContainer = useRef();
  const { currentCardIndex, scrollTo } = useSlider(examplesContainer);
  return (
    <div className="[ CompletedProjects ] [ Content sm:Content_medium ]">
      <div className="[ CompletedProjects__title ] [ Font_header sm:Font_smallHeader Color_black ]">{title}</div>

      <div className="CompletedProjects__pagination">
        {examples.map((_, index) => (
          <button
            key={index}
            className={cn("CompletedProjects__paginationControl", {
              CompletedProjects__paginationControl_active: index === currentCardIndex,
            })}
            disabled={index === currentCardIndex}
            onClick={() => scrollTo(index)}
          />
        ))}
      </div>

      <div className="[ CompletedProjects__examples ] [ FullBleed FullBleed_scroll ]" ref={examplesContainer}>
        {examples.map(({ title, photo, alt, description }, index) => (
          <div
            key={index}
            className={cn("CompletedProjects__example", {
              CompletedProjects__example_active: index === currentCardIndex,
            })}
            onClick={() => scrollTo(index)}
          >
            <Image className="[ CompletedProjects__photo ]" image={photo} alt={alt} />
            {/* <div className="CompletedProjects__exampleTitle [ Font_smallHeader ]">{title}</div>
            <div className="CompletedProjects__description [ Font_mediumText sm:Font_regularText Color_darkGrey ]">
              {description}
            </div> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export { CompletedProjects };
