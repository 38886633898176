import { useEffect, useLayoutEffect, useRef, useState } from "react";

const useSlider = cardsContainer => {
  const [{ currentCardIndex, nextCard, previousCard }, setCards] = useState({
    currentCardIndex: 0,
    nextCard: null,
    previousCard: null,
  });

  const cards = useRef([]);
  const [offsetWidth, setOffsetWidth] = useState(0);

  useLayoutEffect(() => {
    const handleResize = () => {
      if (!cardsContainer.current) return;
      cards.current = Array.from(cardsContainer.current.children);

      setOffsetWidth(cards.current[0] ? cards.current[0].offsetLeft : 0);
    };

    handleResize();
    window.addEventListener("resize", handleResize, { passive: true });
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!cardsContainer.current || !offsetWidth) return;
    const cardsContainerElement = cardsContainer.current;

    const calculateCards = () => {
      const scrollRight =
        cardsContainerElement.scrollWidth - cardsContainerElement.scrollLeft - cardsContainerElement.clientWidth;

      const nextCard =
        scrollRight !== 0
          ? cards.current.find(card => card.offsetLeft > cardsContainerElement.scrollLeft + Math.ceil(offsetWidth))
          : undefined;
      const previousCard = cards.current
        .slice()
        .reverse()
        .find(card => card.offsetLeft < cardsContainerElement.scrollLeft + Math.floor(offsetWidth));

      const currentCardIndex =
        scrollRight !== 0
          ? cards.current.findIndex((card, index) => {
              const currentCardOffset = card.offsetLeft;
              const nextCardOffset = cards.current[index + 1] ? cards.current[index + 1].offsetLeft : 0;
              const scrollPosition = cardsContainerElement.scrollLeft + Math.floor(offsetWidth);

              return currentCardOffset <= scrollPosition && scrollPosition < nextCardOffset;
            })
          : cards.current.length - 1;

      setCards({ currentCardIndex, nextCard, previousCard });
    };

    calculateCards();
    window.addEventListener("resize", calculateCards, { passive: true });
    cardsContainerElement.addEventListener("scroll", calculateCards, {
      passive: true,
    });
    return () => {
      window.removeEventListener("resize", calculateCards);
      cardsContainerElement.removeEventListener("scroll", calculateCards);
    };
  }, [offsetWidth]);

  const scrollToCard = card => {
    if (!card || !cardsContainer.current) return;

    cardsContainer.current.scrollTo({
      top: 0,
      left: card.offsetLeft - offsetWidth,
      behavior: "smooth",
    });
  };

  const scrollToIndex = index => {
    const card = cards.current[index];

    scrollToCard(card);
  };

  const handleNext = () => scrollToCard(nextCard);
  const handlePrevious = () => scrollToCard(previousCard);

  return {
    currentCardIndex,
    isNextCard: !!nextCard,
    isPreviousCard: !!previousCard,
    onNext: handleNext,
    onPrevious: handlePrevious,
    scrollTo: scrollToIndex,
  };
};

export { useSlider };
