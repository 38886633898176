import React from "react";

export const MessageIcon = props => {
  return (
    <svg
      // style={"width: 1em; height: 1em;vertical-align: middle;fill: currentColor;overflow: hidden;"}
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M524.512477 495.313969 361.83749 495.313969c-18.427698 0-33.368992 14.942317-33.368992 33.368992 0 18.428721 14.942317 33.368992 33.368992 33.368992l162.67601 0c18.427698 0 33.368992-14.941294 33.368992-33.368992C557.882492 510.256286 542.940175 495.313969 524.512477 495.313969zM511.999488 144.93802c-211.938993 0-383.746988 145.664567-383.746988 325.349973 0 104.808044 58.45739 198.037379 149.232836 257.541611l0 151.232376 161.626098-89.285512c23.598464 3.846608 47.965431 5.862521 72.888054 5.862521 211.93797 0 383.746988-145.665591 383.746988-325.349973C895.746476 290.602587 723.937458 144.93802 511.999488 144.93802zM511.999488 745.649458c-31.254841 0-61.502749-3.550873-90.194208-10.171664l-101.678774 57.211003 10.030447-91.426269c-91.515297-49.093115-152.038742-134.151349-152.038742-230.877321 0-152.019299 149.485593-275.263228 333.880253-275.263228s333.872067 123.244952 333.872067 275.263228C845.870532 622.413716 696.394149 745.649458 511.999488 745.649458zM628.792495 378.523009 361.83749 378.523009c-18.427698 0-33.368992 14.941294-33.368992 33.368992 0 18.427698 14.942317 33.368992 33.368992 33.368992l266.955005 0c18.427698 0 33.368992-14.942317 33.368992-33.368992C662.161487 393.46328 647.219169 378.523009 628.792495 378.523009z" />
    </svg>
  );
};
