import React, { useState } from "react";
import cn from "classnames";

export const PhoneInput = ({ placeholder, property, errorEmpty, errorFormat }) => {
  const phoneClassnames = cn({
    Form__phoneNumber: true,
    Form__errorEmpty: errorEmpty,
    Form__errorFormat: errorFormat,
  });
  return <input className={phoneClassnames} placeholder={placeholder} {...property} />;
};
