import React from "react";
import cn from "classnames";
export const TextErrors = ({ className, errors }) => {
  return (
    <>
      {errors && (
        <div className={cn(className, "[ TextErrors ]")}>
          {errors.map(
            ({ error, textError }) =>
              error && <div className="[ Form__error ] [ Font_smallText Color_red ]">{textError}</div>
          )}
        </div>
      )}
    </>
  );
};
