import React from "react";

const Close = props => {
  return (
    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.014 4.159a.855.855 0 00-.596 1.468l4.527 4.528-4.527 4.527a.855.855 0 101.21 1.21l4.527-4.528 4.527 4.527a.854.854 0 001.402-.931.854.854 0 00-.192-.278l-4.528-4.527 4.527-4.528a.856.856 0 10-1.209-1.21l-4.527 4.528-4.528-4.527a.855.855 0 00-.613-.26z"
        fill="#7C7C7D"
      />
    </svg>
  );
};

export { Close };
