import React from "react";
import cn from "classnames";

export const Input = ({ placeholder, property, errorEmpty }) => {
  const NameClassnames = cn({
    Form__name: true,
    Form__errorEmpty: errorEmpty,
  });
  return <input className={`[ ${NameClassnames} ]`} type="text" placeholder={placeholder} {...property} />;
};
