import React from "react";
import { Footer } from "../Footer";
import { Header } from "../Header";
import { SocialIconWidget } from "../SocialIconWidget/SocialIconWidget";
import "./styles.css";
import { graphql } from 'gatsby'

const Layout = ({
  address,
  email,
  phoneNumbers,
  logo,
  form,
  contactsLabel,
  logoWhite,
  children,
  onOpenContactUsModal,
  lang,
}) => {
  return (
    <div className="Layout">
      <Header
        address={address}
        email={email}
        phoneNumbers={phoneNumbers}
        logo={logo}
        onOpenContactUsModal={onOpenContactUsModal}
        lang={lang}
      />

      <div className="Layout__content">{children}</div>

      {/* {setTimeout(
        () => (
          <SocialIconWidget />
        ),
        300
        
      )} */}
      <SocialIconWidget />
      <Footer
        form={form}
        contactsLabel={contactsLabel}
        address={address}
        email={email}
        phoneNumbers={phoneNumbers}
        logoWhite={logoWhite}
        lang={lang}
      />
    </div>
  );
};

Layout.getLivePreviewData = ({ data, getAsset }) => ({
  address: "Минск, ул. Инженерная, 24",
  email: "info@init.by",
  phoneNumbers: ["+375 (17) 344-89-31", "+375 (17) 344-53-31"],
  logo: {
    publicURL: "/img/логотип.svg",
  },
  logoWhite: {
    publicURL: "/img/логотип-белый.svg",
  },
});

const layoutQuery = graphql`
  fragment LayoutFragment on MarkdownRemarkFrontmatter {
    address
    email
    phoneNumbers
    logo {
      publicURL
    }

    form {
      title
      text
      namePlaceholder
      phoneNumberPlaceholder
      buttonLabel
      policy
      successMessage
      successButtonLabel
      errorMessage
      errorButtonLabel
    }

    contactsLabel
    logoWhite {
      publicURL
    }
  }
`;

export { Layout, layoutQuery };
