/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState, useCallback } from "react";
import "./styles.css";
import { MessageIcon } from "./MessageIcon";
import { CloseIcon } from "./CloseIcon";
import cn from "classnames";
import { TelegramIcon } from "./TelegramIcon";
import { WhatsAppIcon } from "./WhatsAppIcon";
import { ViberIcon } from "./ViberIcon";

export const SocialIconWidget = () => {
  const [isOpen, setIsOpen] = useState(false);

  const socialButtons = [
    {
      name: "telegram",
      link: "https://t.me/EvgenyKunaev",
      icon: <TelegramIcon background="rgb(33 150 243)" color="white" />,
      background: "none",
    },
    {
      name: "whatsapp",
      link: "https://wa.me/375296404606",
      icon: <WhatsAppIcon background="rgb(23 188 81)" color="white" />,
      background: "none",
    },
    {
      name: "viber",
      link: "https://msng.link/o/?375296404606=vi",
      icon: <ViberIcon background="rgb(188, 23, 166)" color="white" />,
      background: "none",
    },
  ];

  const handleClick = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  const SocialIconWidgetClassNames = cn({
    SocialIconWidget__button: true,
    SocialIconWidget__open: isOpen,
  });

  const classNamesIconMessage = cn({
    SocialIconWidget__icon: true,
    SocialIconWidget__hideIcon: isOpen,
  });

  const classNamesIconClose = cn({
    SocialIconWidget__iconClose: true,
    SocialIconWidget__hideIcon: !isOpen,
  });

  const classNamesSocialIconGroup = cn({
    SocialIconWidget__socilaIconGroup: true,
    SocialIconWidget__socilaIconGroup__open: isOpen,
    SocialIconWidget__socilaIconGroup__close: !isOpen,
  });
  return (
    <div className="SocialIconWidget">
      <ul className={classNamesSocialIconGroup}>
        {socialButtons.map(({ name, link, icon, background }, index) => (
          <li
            style={{
              background,
              transition: "all 0.2s ease-in-out",
              opacity: isOpen ? "1" : "0",
              transform: isOpen ? `translate(0, 0)` : `translate(0, ${120 * (socialButtons.length - index)}%)`,
            }}
          >
            <a
              className="SocialIconWidget__socilaIconGroup__Icon"
              href={link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="SocialIconWidget__socilaIconGroup__IconTooltip">{name}</span>
              {icon}
            </a>
          </li>
        ))}
      </ul>

      <div className={SocialIconWidgetClassNames} onClick={handleClick}>
        <MessageIcon className={classNamesIconMessage} />
        <CloseIcon className={classNamesIconClose} />
      </div>
    </div>
  );
};
