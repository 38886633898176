import React, { useCallback, useEffect, useRef, useState } from "react";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import { ExpandArrow } from "../../../icons/ExpandArrow";
import { DropDownMenu, DropDownMenuLink } from "../../DropDownMenu";
import "./styles.css";

const SolutionsMenu = ({ lang }) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const handleToggleMenu = useCallback(() => setIsOpenMenu(!isOpenMenu), [isOpenMenu]);
  const handleCloseMenu = useCallback(() => setIsOpenMenu(false), []);

  const menu = useRef(null);
  useOnClickOutside(menu, handleCloseMenu);

  useEffect(() => {
    window.addEventListener("scroll", handleCloseMenu, { passive: true });
  });

  const shopEquipmentLink = "/shop-equipment" + (lang === "en" ? "-en" : "");
  const cashAreaEquipmentLink = "/cash-area-equipment" + (lang === "en" ? "-en" : "");
  const ceramicTileHolderLink = "/ceramic-tile-holder" + (lang === "en" ? "-en" : "");
  const metalConstructionsLink = "/metal-constructions" + (lang === "en" ? "-en" : "");
  // const hooksLink = "/hangers" + (lang === "en" ? "-en" : "");
  const selfCheckoutLink = "/self-checkout" + (lang === "en" ? "-en" : "");

  return (
    <li className="SolutionsMenu" ref={menu}>
      <button className="[ SolutionsMenu__button ] [ Font_regularText Color_darkGrey ]" onClick={handleToggleMenu}>
        {lang === "en" ? "Production" : "Продукция"}
        <ExpandArrow />
      </button>

      <DropDownMenu className="SolutionsMenu__menu" isOpen={isOpenMenu} onClose={handleCloseMenu}>
        <DropDownMenuLink href={shopEquipmentLink} onClick={handleCloseMenu}>
          {lang === "en" ? "Shop equipment" : "Для магазинов"}
        </DropDownMenuLink>
        <DropDownMenuLink href={cashAreaEquipmentLink} onClick={handleCloseMenu}>
          {lang === "en" ? "Checkout area equipment" : "Для кассовых зон"}
        </DropDownMenuLink>
        <DropDownMenuLink href={ceramicTileHolderLink} onClick={handleCloseMenu}>
          {lang === "en" ? "Tile display stands" : "Стеллажи для плитки"}
        </DropDownMenuLink>
        <DropDownMenuLink href={metalConstructionsLink} onClick={handleCloseMenu}>
          {lang === "en" ? "Metal frames" : "Металлокаркасы"}
        </DropDownMenuLink>
        <DropDownMenuLink href={selfCheckoutLink} onClick={handleCloseMenu}>
          {lang === "en" ? "Self-checkout" : "Касса самообслуживания"}
        </DropDownMenuLink>
      </DropDownMenu>
    </li>
  );
};

export { SolutionsMenu };
