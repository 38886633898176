import React from "react";

export const CloseIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      class="t898__icon t898__icon-close"
      viewBox="0 0 23 23"
      {...props}
    >
      <g fillrule="evenodd">
        <path d="M10.314 -3.686H12.314V26.314H10.314z" transform="rotate(-45 11.314 11.314)"></path>{" "}
        <path d="M10.314 -3.686H12.314V26.314H10.314z" transform="rotate(45 11.314 11.314)"></path>{" "}
      </g>
    </svg>
  );
};
