import { useEffect, useState } from "react";

const useSwitchLang = currentLang => {
  const [link, setLink] = useState("/");

  const getPathAfterChangingLanguage = lang => {
    const pathName = window.location.pathname;

    const shortPath = pathName.endsWith("/") ? pathName.slice(0, -1) : pathName;

    if (shortPath === "") return "/en";
    if (shortPath === "/en") return "/";

    return shortPath.endsWith("-en") ? shortPath.slice(0, -3) : shortPath + "-en";
  };

  useEffect(() => {
    setLink(getPathAfterChangingLanguage(currentLang));
  }, [currentLang]);

  return link;
};

export { useSwitchLang };
